import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PageLoaderService {
  private loading = new Subject<boolean>();
  pageLoader = this.loading.asObservable();
  constructor() {}

  change(value) {
    this.loading.next(value);
  }
}
