import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { APIService } from "../api.service";
import { RedirectService } from "../redirect.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { TranslateService } from "@ngx-translate/core";
import { PageLoaderService } from "../page-loader.service";
import _ from "lodash";

@Component({
  selector: "app-verified-mail",
  templateUrl: "./verified-mail.component.html",
  styleUrls: ["./verified-mail.component.scss"],
})
export class VerifiedMailComponent implements OnInit {
  isMobile: any;
  isTablet: any;
  isDesktop: any;
  loading: boolean;
  pageLoader: any = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private API: APIService,
    private redirect: RedirectService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private load: PageLoaderService
  ) {}

  leaveLoader() {
    if (_.isEmpty(this.translate.store.translations)) {
      setTimeout(() => {
        this.leaveLoader();
      }, 500);
    } else {
      this.pageLoader = true;
      this.load.change(this.pageLoader);
    }
  }

  async ngOnInit() {
    this.load.change(this.pageLoader);
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    let mailToken = localStorage.getItem("mailToken");

    if (!mailToken) {
      this.route.queryParams.subscribe((params) => {
        mailToken = params["mail_token"];
        localStorage.setItem("mailToken", mailToken);
      });
    }
    if (localStorage.getItem("token")) {
      let resp = await this.API.getMe();
      if (resp.status === 200) {
        this.API.setUsername(
          resp.data.username,
          `${resp.data.firstName
            .charAt(0)
            .toUpperCase()}${resp.data.lastName.charAt(0).toUpperCase()}`
        );

        let validateMail = await this.API.validateMail(mailToken);
        if (validateMail.status !== 200) {
          this.router.navigate(["error-page"], {
            queryParams: { codeError: validateMail.data.code },
          });
          localStorage.removeItem("mailToken");
        } else {
          localStorage.removeItem("mailToken");
          this.leaveLoader();
        }
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }

  async validateMail() {
    this.loading = true;
    setTimeout(async () => {
      var getMe = await this.API.getMe();
      if (getMe.status === 200) {
        this.router.navigate(["new-password"]);
      }
    }, 500);
  }
}
