import { Component, OnInit } from "@angular/core";
import { APIService } from "../api.service";
import { RedirectService } from "../redirect.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { TranslateService } from "@ngx-translate/core";
import { PageLoaderService } from "../page-loader.service";
import _ from "lodash";

@Component({
  selector: "app-data-check",
  templateUrl: "./data-check.component.html",
  styleUrls: ["./data-check.component.scss"],
})
export class DataCheckComponent implements OnInit {
  name: string;
  pageLoader: any = false;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  constructor(
    private API: APIService,
    private redirect: RedirectService,
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private load: PageLoaderService
  ) {}

  leaveLoader() {
    if (_.isEmpty(this.translate.store.translations)) {
      setTimeout(() => {
        this.leaveLoader();
      }, 500);
    } else {
      this.pageLoader = true;
      this.load.change(this.pageLoader);
    }
  }

  async ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    this.load.change(this.pageLoader);
    if (localStorage.getItem("token")) {
      let resp = await this.API.getMe();
      if (resp) {
        this.API.setUsername(
          resp.data.username,
          `${resp.data.firstName
            .charAt(0)
            .toUpperCase()}${resp.data.lastName.charAt(0).toUpperCase()}`
        );
        this.name = resp.data.firstName;
        if (resp.data.status !== 40) {
          this.redirect.redirect(resp.data.status);
        } else {
          localStorage.clear();
          this.leaveLoader();
        }
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }
}
