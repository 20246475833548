import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { APIService } from "../api.service";
import { FormGroup, FormControl } from "@angular/forms";
import { RedirectService } from "../redirect.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { TranslateService } from "@ngx-translate/core";
import { PageLoaderService } from "../page-loader.service";
import _ from "lodash";

@Component({
  selector: "personal-email",
  templateUrl: "./personal-email.component.html",
  styleUrls: ["./personal-email.component.scss"],
})
export class PersonalEmailComponent implements OnInit {
  @ViewChild("infoTerm", { static: false }) infoTerm: ElementRef;
  lang: string;
  email: string;
  confirmEmail: string;
  term: boolean;
  actived: boolean;
  loading: boolean;
  personalEmailForm = new FormGroup({
    email: new FormControl(""),
    confirmEmail: new FormControl(""),
    term: new FormControl(""),
  });
  emailError: boolean;
  confirmEmailError: boolean;
  differentEmailError: boolean;
  usedEmail: boolean;
  isMobile: any;
  isTablet: any;
  isDesktop: any;
  pageLoader: any = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private API: APIService,
    private redirect: RedirectService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private load: PageLoaderService
  ) {}

  leaveLoader() {
    if (_.isEmpty(this.translate.store.translations)) {
      setTimeout(() => {
        this.leaveLoader();
      }, 500);
    } else {
      this.pageLoader = true;
      this.load.change(this.pageLoader);
    }
  }

  async ngOnInit() {
    this.load.change(this.pageLoader);
    this.email = "";
    this.confirmEmail = "";
    this.term = false;
    this.actived = false;
    this.emailError = false;
    this.confirmEmailError = false;
    this.differentEmailError = false;
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    this.lang = this.translate.currentLang.toUpperCase();
    if (localStorage.getItem("token")) {
      let resp = await this.API.getMe();
      if (resp.status === 200) {
        this.API.setUsername(
          resp.data.username,
          `${resp.data.firstName
            .charAt(0)
            .toUpperCase()}${resp.data.lastName.charAt(0).toUpperCase()}`
        );
        if (resp.data.status !== 10) {
          this.redirect.redirect(resp.data.status);
        } else {
          this.leaveLoader();
        }
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }

  validatorMail(mail) {
    let regex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm;
    return mail.match(regex) ? true : false;
  }

  confirmMail(e) {
    let validate = this.validatorMail(e.target.value);
    if (!validate && e.target.value !== "") {
      this[e.target.id + "Error"] = true;
    } else {
      this[e.target.id + "Error"] = false;
    }

    if (
      this.email !== "" &&
      this.confirmEmail !== "" &&
      this.email !== this.confirmEmail
    ) {
      this.differentEmailError = true;
    } else {
      this.differentEmailError = false;
      this.setValue(e.target.value);
    }
  }

  setValue(email) {
    this.usedEmail = false;
    let validInput =
      typeof email === "boolean" ? true : this.validatorMail(email);
    if (
      this.email !== "" &&
      this.confirmEmail !== "" &&
      this.term &&
      this.email === this.confirmEmail &&
      !this.differentEmailError &&
      !this.emailError &&
      !this.confirmEmailError &&
      validInput
    ) {
      this.actived = true;
    } else {
      this.actived = false;
    }
  }

  async sendPersonalMail() {
    this.loading = true;
    let lang=this.translate.currentLang.toUpperCase();
    var response = await this.API.postPersonalMail(this.email, lang);
    setTimeout(() => {
      if (response.status === 200) {
        this.router.navigate(["mail-send"]);
      } else {
        if (response.data.status === 10) {
          this.usedEmail = true;
        }
        this.loading = false;
      }
    }, 500);
  }

  showInfo() {
    //prendere lingua impostata
    window.open(
      `../../assets/pdf/NOTE_LEGALI___RACCOLTA_DATI_WEB_DIPENDENTI_rev08092020_CLEAN_${this.lang}.pdf`,
      "_blank"
    );
  }
}
