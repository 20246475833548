import { Component, OnInit } from "@angular/core";
import { APIService } from "../api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { RedirectService } from "../redirect.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { PageLoaderService } from "../page-loader.service";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import {
  TranslateService,
  TranslationChangeEvent,
  LangChangeEvent,
} from "@ngx-translate/core";
import _ from "lodash";
import { Subscription } from "rxjs";

@Component({
  selector: "first-login",
  templateUrl: "./first-login.component.html",
  styleUrls: ["./first-login.component.scss"],
})
export class FirstLoginComponent implements OnInit {
  googleId: any;
  password: any;
  actived: boolean;
  error: boolean;
  loading: boolean;
  errorRegister: boolean;
  loginForm = new FormGroup({
    googleId: new FormControl(""),
    password: new FormControl(""),
  });
  isMobile: any;
  isTablet: any;
  isDesktop: any;
  pageLoader: any = false;
  constructor(
    private API: APIService,
    private redirect: RedirectService,
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private load: PageLoaderService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  public recentToken: string = "";
  public readonly executionLog: OnExecuteData[] = [];

  private allExecutionsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;

  executeAction(action: string): void {}

  public ngOnDestroy() {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  public formatToken(token: string): string {
    if (!token) {
      return "(empty)";
    }

    return `${token.substr(0, 7)}...${token.substr(-7)}`;
  }

  leaveLoader() {
    if (_.isEmpty(this.translate.store.translations)) {
      setTimeout(() => {
        this.leaveLoader();
      }, 500);
    } else {
      this.pageLoader = true;
      this.load.change(this.pageLoader);
    }
  }

  async ngOnInit() {
    this.allExecutionsSubscription = this.recaptchaV3Service.onExecute.subscribe(
      (data) => this.executionLog.push(data)
    );
    this.load.change(this.pageLoader);
    this.googleId = "";
    this.password = "";
    this.actived = false;
    this.error = false;
    this.errorRegister = false;
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    if (localStorage.getItem("token")) {
      let resp = await this.API.getMe();
      if (resp.status === 200) {
        this.redirect.redirect(resp.data.status);
      } else {
        localStorage.removeItem("token");
      }
    } else {
      this.leaveLoader();
    }
  }

  public resolved(captchaResponse: string) {}

  setValue(e) {
    if (this.googleId !== "" && this.password !== "") {
      this.actived = true;
      this.error = false;
    } else {
      this.actived = false;
      this.error = false;
    }
  }

  async login(action) {
    this.error = false;
    this.errorRegister = false;
    this.loading = true;
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }

    this.singleExecutionSubscription = this.recaptchaV3Service
      .execute(action)
      .subscribe(async (token) => {
        this.recentToken = token;
        if (this.googleId !== "" && this.password !== "") {
          var response = await this.API.auth(
            this.googleId,
            this.password,
            token
          );
          setTimeout(async () => {
            if (response.status === 200) {
              this.error = false;
              var token = response.data.token;
              localStorage.setItem("token", token);
              var getMe = await this.API.getMe();
              if (getMe !== false) {
                this.redirect.redirect(getMe.data.status);
              }
            } else if (response.status === 403) {
              this.errorRegister = true;
              this.loading = false;
            } else {
              this.error = true;
              this.loading = false;
            }
          }, 500);
        }
      });
  }
}
