import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { APIService } from "../api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { RedirectService } from "../redirect.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { TranslateService } from "@ngx-translate/core";
import { PageLoaderService } from "../page-loader.service";
import _ from "lodash";

@Component({
  selector: "app-new-password",
  templateUrl: "./new-password.component.html",
  styleUrls: ["./new-password.component.scss"],
})
export class NewPasswordComponent implements OnInit {
  @ViewChild("infoPass", { static: false }) infoPass: ElementRef;
  name: string;
  surname: string;
  userId: string;
  password: string;
  confirmPassword: string;
  newPasswordForm = new FormGroup({
    password: new FormControl(""),
    confirmPassword: new FormControl(""),
  });
  passwordError: boolean;
  confirmPasswordError: boolean;
  differentPasswordError: boolean;
  actived: boolean;
  loading: boolean;
  isMobile: any;
  isTablet: any;
  isDesktop: any;
  pageLoader: any = false;
  showPsw: any = false;
  showConfirmPsw: any = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private API: APIService,
    private redirect: RedirectService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private load: PageLoaderService
  ) { }

  leaveLoader() {
    if (_.isEmpty(this.translate.store.translations)) {
      setTimeout(() => {
        this.leaveLoader();
      }, 500);
    } else {
      this.pageLoader = true;
      this.load.change(this.pageLoader);
    }
  }

  async ngOnInit() {
    this.load.change(this.pageLoader);
    this.password = "";
    this.confirmPassword = "";
    this.passwordError = false;
    this.confirmPasswordError = false;
    this.differentPasswordError = false;
    this.actived = false;
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    if (localStorage.getItem("token")) {
      let resp = await this.API.getMe();
      if (resp.status === 200) {
        this.API.setUsername(
          resp.data.username,
          `${resp.data.firstName
            .charAt(0)
            .toUpperCase()}${resp.data.lastName.charAt(0).toUpperCase()}`
        );
        this.name = resp.data.firstName;
        this.surname = resp.data.lastName;
        this.userId = resp.data.UID;
        this.leaveLoader();
        if (resp.data.status !== 30) {
          this.redirect.redirect(resp.data.status);
        }
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }

  regexPassword(psw) {
    let regex = /^([^\s]){8,}$/gm;
    var valid;
    var count = 0;
    count += /[a-z]/.test(psw) ? 1 : 0;
    count += /[A-Z]/.test(psw) ? 1 : 0;
    count += /\d/.test(psw) ? 1 : 0;
    count += /[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?\/)]/.test(psw) ? 1 : 0;

    let completeName = `${this.name} ${this.surname}`
    let wordsName = _.words(completeName.toLowerCase())
    console.log("NewPasswordComponent -> regexPassword -> wordsName", wordsName)


    if (regex.test(psw)) {
      if (
        psw.toLowerCase().indexOf(this.userId.toLowerCase()) < 0
      ) {
        for (let i = 0; i < wordsName.length; i = i + 1) {
          console.log(wordsName[i], psw.toLowerCase().indexOf(wordsName[i]));
          if (wordsName[i].length > 2) {
            if (psw.toLowerCase().indexOf(wordsName[i]) < 0) {
              if (count > 2) {
                valid = true;
              } else {
                valid = false;
              }
            } else {
              valid = false;
              i = wordsName.length;
            }
          }
        }
      } else {
        valid = false;
      }
    } else {
      if (psw.length === 0) {
        valid = true;
      } else {
        valid = false;
      }
    }
    return valid;
  }

  validatorPassword(e) {
    let validate = this.regexPassword(e.target.value);
    if (validate) {
      this[e.target.id + "Error"] = false;
    } else {
      this[e.target.id + "Error"] = true;
    }
    if (
      this.password !== "" &&
      this.confirmPassword !== "" &&
      this.password !== this.confirmPassword
    ) {
      this.differentPasswordError = true;
    } else {
      this.differentPasswordError = false;
      this.activedButton(e.target.value);
    }
  }

  activedButton(psw) {
    if (
      this.password !== "" &&
      this.confirmPassword !== "" &&
      this.password === this.confirmPassword &&
      !this.passwordError &&
      !this.confirmPasswordError &&
      this.regexPassword(psw)
    ) {
      this.actived = true;
    } else {
      this.actived = false;
    }
  }

  prevPage() {
    this.router.navigate(["security-questions"]);
  }

  async setNewPassword() {
    this.loading = true;
    let quest = JSON.parse(localStorage.getItem("quest"));
    let lang=this.translate.currentLang.toUpperCase();
    let resp = await this.API.postNewPassword({
      ...quest,
      password: this.password,
      lang:lang
    });

    setTimeout(() => {
      if (resp.status === 200) {
        localStorage.removeItem("quest");
        this.router.navigate(["data-check"]);
      } else {
        this.router.navigate(["error-page"], {
          queryParams: { codeError: 30 },
        });
      }
    }, 500);
  }

  showInfo() {
    this.infoPass.nativeElement.classList.remove("hide");
    this.infoPass.nativeElement.classList.add("show");
  }

  closeInfo() {
    this.infoPass.nativeElement.classList.remove("show");
    this.infoPass.nativeElement.classList.add("hide");
  }

  showPswF(id) {
    this[id] = !this[id];
  }
}
