import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import _ from "lodash";
import { Router } from "@angular/router";
import { APIService } from "../api.service";
import { TranslateService } from "@ngx-translate/core";
import { PageLoaderService } from "../page-loader.service";
import $ from "jquery";

@Component({
  selector: "app-login-admin",
  templateUrl: "./login-admin.component.html",
  styleUrls: ["./login-admin.component.scss"],
})
export class LoginAdminComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private API: APIService,
    private translate: TranslateService,
    private load: PageLoaderService
  ) {
    this.matIconRegistry.addSvgIcon(
      "logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "https://raw.githubusercontent.com/fireflysemantics/logo/master/Google.svg"
      )
    );
  }
  user: any;
  loggedIn: boolean = false;
  pageLoader: boolean = false;

  leaveLoader() {
    if (_.isEmpty(this.translate.store.translations)) {
      setTimeout(() => {
        this.leaveLoader();
      }, 500);
    } else {
      this.pageLoader = true;
      this.load.change(this.pageLoader);
      $(".page-container").addClass("desktop");
    }
  }

  ngOnInit() {
    this.user = [];
    this.leaveLoader();
    $(".page-container").addClass("desktop");
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = user != null;
    });
  }

  async signInWithGoogle() {
    await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    if (!_.isEmpty(this.user)) {
      localStorage.setItem("tokenId", this.user.idToken);
      localStorage.setItem("user", JSON.stringify(this.user));
    }
    if (
      localStorage.getItem("tokenId") &&
      localStorage.getItem("tokenId") !== ""
    ) {
      this.router.navigate(["/admin"]);
    }
  }
}
