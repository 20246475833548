import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { APIService } from "../api.service";
import { RedirectService } from "../redirect.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { PageLoaderService } from "../page-loader.service";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";
@Component({
  selector: "app-error-page",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
})
export class ErrorPageComponent implements OnInit {
  name: string;
  codeError: any;
  pageLoader: any = false;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  loading: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private API: APIService,
    private redirect: RedirectService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private load: PageLoaderService
  ) {}

  leaveLoader() {
    if (_.isEmpty(this.translate.store.translations)) {
      setTimeout(() => {
        this.leaveLoader();
      }, 500);
    } else {
      this.pageLoader = true;
      this.load.change(this.pageLoader);
    }
  }

  async ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    this.load.change(this.pageLoader);
    this.leaveLoader();
    this.route.queryParams.subscribe((params) => {
      this.codeError = params["codeError"];
    });
    if (localStorage.getItem("token")) {
      let resp = await this.API.getMe();
      if (resp) {
        this.API.setUsername(
          resp.data.username,
          `${resp.data.firstName
            .charAt(0)
            .toUpperCase()}${resp.data.lastName.charAt(0).toUpperCase()}`
        );
        this.name = resp.data.firstName;
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }

  resolveError(codeError) {
    this.loading = true;
    setTimeout(async () => {
      if (localStorage.getItem("token")) {
        let resp = await this.API.getMe();
        if (resp.status === 200) {
          if (String(codeError) === "10") {
            this.API.logOut("");
            this.API.setUsername("", "");
            // this.API.resendMail();
            // this.redirect.redirect(resp.data.status);
          } else if (String(codeError) === "20") {
            let clean = await this.API.mailClean();
            if (clean.status === 200) {
              this.redirect.redirect(resp.data.status);
            } else {
              this.loading = false;
            }
          } else if (String(codeError) === "30") {
            this.redirect.redirect(30);
          }
        } else {
          this.router.navigate(["/login"]);
        }
      } else {
        this.router.navigate(["/login"]);
      }
    }, 500);
  }
}
