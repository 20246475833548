import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { APIService } from '../api.service';
import { PageLoaderService } from '../page-loader.service';

@Component({
  selector: 'app-reset-mail',
  templateUrl: './reset-mail.component.html',
  styleUrls: ['./reset-mail.component.scss']
})
export class ResetMailComponent implements OnInit {
  pageLoader: any = false;
  loading: boolean;
  isMobile: any;
  isTablet: any;
  isDesktop: any;
  email:any;
  emailError:any;
  actived:any;
  result:any;
  success:any;
 resetEmailForm = new FormGroup({
    email: new FormControl(""),
  });
  constructor(    
    private API: APIService,
    private router: Router,
    private load: PageLoaderService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute
) { 
}

//loader function
leaveLoader() {
  if (_.isEmpty(this.translate.store.translations)) {
    setTimeout(() => {
      this.leaveLoader();
    }, 500);
  } else {
    this.pageLoader = true;
    this.load.change(this.pageLoader);
    $(".page-container").addClass("desktop");
  }
}

    async ngOnInit() {
      this.route.queryParams.subscribe((params) => {
        this.result = params["result"];
        if(this.result=="success"){
          this.success=true
        } else if(this.result=="error"){
          this.success=false
        }
      });
      this.emailError = false;
      this.pageLoader = false;
      this.load.change(this.pageLoader);
      this.isMobile = this.deviceService.isMobile();
      this.isTablet = this.deviceService.isTablet();
      this.isDesktop = this.deviceService.isDesktop();
      let user = JSON.parse(localStorage.getItem("user"));
      let tokenId = localStorage.getItem("tokenId");
      if (!tokenId || tokenId === "") {
        this.router.navigate(["/admin/auth"]);
      } else if (user) {
        await this.API.setUsername(
          user.email,
          `${user.firstName.charAt(0).toUpperCase()}${user.lastName
            .charAt(0)
            .toUpperCase()}`
        );
      }
      this.leaveLoader();
    }

    validatorMail(mail) {
      let regex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm;
      return mail.match(regex) ? true : false;
    }

    confirmMail(e) {
      let validate = this.validatorMail(e.target.value);
      if (!validate && e.target.value !== "") {
        this.emailError = true;
      } else {
        this.emailError = false;
        this.setValue(e.target.value);
      }
    }

    setValue(email) {
      let validInput = this.validatorMail(email);
      if (
        this.email !== "" &&
        !this.emailError &&
        validInput
      ) {
        this.actived = true;
      } else {
        this.actived = false;
      }
    }

    async resetMail() {
      this.loading = true;
      var response = await this.API.resetMail(this.email);
      
      setTimeout(() => {
        if (response.status === 200) {
          this.router.navigate(["admin/reset-mail"], {
            queryParams: { result: "success" },
          });
          this.loading=false;
        } else {
          this.router.navigate(["admin/reset-mail"], {
            queryParams: { result: "error" },
          });
          this.loading=false;
        }
      }, 500);
    }

    successResetMail(){
  this.success=undefined;
  this.email="";
      this.router.navigate(["admin"]);
    }

    errorResetMail(){
  this.success=undefined;
  this.email="";
      this.router.navigate(["admin/reset-mail"]);
    }
}
