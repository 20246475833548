import { Injectable } from "@angular/core";
import axios from "axios";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthService } from "angularx-social-login";
import { Router } from "@angular/router";

import { environment } from "../environments/environment";

// PROD
const URL = environment.URL;

@Injectable({
  providedIn: "root",
})
export class APIService {
  private username: BehaviorSubject<string>;
  private icon: BehaviorSubject<string>;
  constructor(private authService: AuthService, private router: Router) {
    this.username = new BehaviorSubject<string>("");
    this.icon = new BehaviorSubject<string>("");
  }
  user: any;

  getUsername(): Observable<string> {
    return this.username.asObservable();
  }

  getIcon(): Observable<string> {
    return this.icon.asObservable();
  }

  setUsername(username, icon) {
    this.username.next(username);
    this.icon.next(icon);
  }

  async callApi(options) {
    try {
      let resp = await axios(options);
      return resp;
    } catch (e) {
      console.log(`API ERROR: ${e.toString()}`);
      return e.response;
    }
  }

  auth(username, password, googleToken) {
    return axios
      .post(`${URL}/auth/login`, {
        username: username.toLowerCase(),
        password: password,
        token: googleToken,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async getMe() {
    let resp = await this.callApi({
      method: "GET",
      url: `${URL}/actions/me`,
      headers: {
        token: localStorage.getItem("token"),
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
      },
    });
    if (resp.status !== 200) {
      window.location.href = "/login";
    }
    return resp;
  }

  logOut(currentPage) {
    localStorage.clear();
    if (currentPage === "/admin") {
      this.authService.signOut();
      this.router.navigate(["/admin/auth"]);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  postPersonalMail(personalMail,lang) {
    return this.callApi({
      method: "POST",
      url: `${URL}/actions/mailconfirm`,
      data: {
        mail: personalMail.toLowerCase(),
        lang:lang
      },
      headers: {
        token: localStorage.getItem("token"),
      },
    });
  }

  resendMail(lang) {
    return this.callApi({
      method: "POST",
      url: `${URL}/actions/mailreconfirm`,
      data:{lang:lang},
      headers: {
        token: localStorage.getItem("token"),
      },
    });
  }

  validateMail(mailToken) {
    return this.callApi({
      method: "POST",
      url: `${URL}/actions/mailvalidate`,
      data: {
        mail_token: mailToken,
      },
      headers: {
        token: localStorage.getItem("token"),
      },
    });
  }

  mailClean() {
    return this.callApi({
      method: "POST",
      url: `${URL}/actions/mailclean`,
      headers: {
        token: localStorage.getItem("token"),
      },
    });
  }

  postNewPassword(data) {
    return this.callApi({
      method: "POST",
      url: `${URL}/actions/savedata`,
      data: data,
      headers: {
        token: localStorage.getItem("token"),
      },
    });
  }

  getStatusList() {
    return this.callApi({
      method: "GET",
      url: `${URL}/admin/filters/statuses`,
      headers: {
        token: localStorage.getItem("tokenId"),
        gid: JSON.parse(localStorage.getItem("user")).email,
      },
    });
  }

  resetMail(mail){
    return this.callApi({
      method: "POST",
      url: `${URL}/admin/userreset`,
      headers: {
        token: localStorage.getItem("token"),
        gid: JSON.parse(localStorage.getItem("user")).email,
      },
      data: {mail: mail}
    });
  }

  getCompanyList() {
    return this.callApi({
      method: "GET",
      url: `${URL}/admin/groups/company`,
      headers: {
        token: localStorage.getItem("tokenId"),
        gid: JSON.parse(localStorage.getItem("user")).email,
      },
    });
  }

  async getCountriesList() {
    return this.callApi({
      method: "GET",
      url: `${URL}/admin/groups/countries`,
      headers: {
        token: localStorage.getItem("tokenId"),
        gid: JSON.parse(localStorage.getItem("user")).email,
      },
    });
  }

  getUserList(queryparams) {
    return this.callApi({
      method: "GET",
      url: `${URL}/admin/users${queryparams}`,
      headers: {
        token: localStorage.getItem("tokenId"),
        gid: JSON.parse(localStorage.getItem("user")).email,
      },
    });
  }

  getCounter() {
    return this.callApi({
      method: "GET",
      url: `${URL}/admin/counters`,
      headers: {
        token: localStorage.getItem("tokenId"),
        gid: JSON.parse(localStorage.getItem("user")).email,
      },
    });
  }
}
