import { Component } from "@angular/core";
import { APIService } from "./api.service";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { PageLoaderService } from "./page-loader.service";
import { RedirectService } from "./redirect.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "FCA-UPP-FE";
  email: any;
  pageLoder: boolean;
  icon: any;
  isMobile: any;
  isTablet: any;
  isDesktop: any;
  deviceInfo: any;
  lang: any;
  mode: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private API: APIService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private load: PageLoaderService,
    private redirect: RedirectService
  ) {
    translate.setDefaultLang("it");
    this.lang = 'it'
    translate.use("it");
    // translate.setDefaultLang("en");
    // this.lang = window.navigator.language.slice(0, 2);
    // translate.use(this.lang);
    load.pageLoader.subscribe((value) => {
      this.pageLoder = value;
    });
  }

  ngOnInit() {
    let currentPage = this.redirect.currentPage();
    this.API.getUsername().subscribe((value) => {
      this.email = value;
    });
    this.API.getIcon().subscribe((value) => {
      this.icon = value;
    });
    this.load.change(true);
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.isMobile === true || this.isTablet === true) {
      document.body.style.backgroundColor = "white";
    }

    this.mode = this.isMobile
      ? "mobile"
      : this.isTablet
      ? "tablet"
      : this.isDesktop
      ? "desktop"
      : "";

    const node = document.createElement("script");
    let key;
    if (this.translate.currentLang === "it") {
      key = 2610;
    } else if (this.translate.currentLang === "bp") {
      key = 2612;
    } else if (this.translate.currentLang === "es") {
      key = 2613;
    } else if (this.translate.currentLang === "fr") {
      key = 2614;
    } else if (this.translate.currentLang === "pl") {
      key = 2615;
    } else if (this.translate.currentLang === "sr") {
      key = 2616;
    } else {
      key = 2611;
    }
    node.src = `https://cookielaw.emea.fcagroup.com/CookieLawProduct/resources/generatejs?key=${key}`;
    node.type = "text/javascript";
    node.async = false;
    document.getElementsByTagName("HEAD")[0].appendChild(node);
    const link = document.createElement("link");
    link.href = `https://cookielaw.emea.fcagroup.com/CookieLawProduct/resources/generatecss?key=${key}`;
    link.rel = "stylesheet";
    link.type = "text/css";
    document.getElementsByTagName("HEAD")[0].appendChild(link);
  }

  logOut() {
    var currentPage = this.redirect.currentPage();
    this.API.setUsername("", "");
    this.API.logOut(currentPage);
  }

  changeLang(lang) {
    this.lang = lang;
    this.translate.use(lang);
  }
}
