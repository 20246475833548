import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { APIService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class RedirectService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private API: APIService
  ) {}

  async redirect(status) {
    let mailToken = localStorage.getItem("mailToken");
    if (status === 10) {
      this.router.navigate(["/personal-email"]);
    } else if (status === 20 && !mailToken) {
      this.router.navigate(["/mail-send"]);
    } else if (status === 20 && mailToken) {
      this.router.navigate(["/verified-mail"]);
    } else if (status === 30) {
      this.router.navigate(["/new-password"]);
    } else if (status === 40) {
      this.router.navigate(["/data-check"]);
    }
  }

  currentPage() {
    return this.router.url;
  }
}
